/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var AC = {
    // All pages
    'common': {
      init: function() {
        var that = this;

        this.initCookieWarning();
        this.initSideNav();
        this.initNavDropDown();
        this.initScrollToTop();
        this.initStickyNav();
        this.initTooltip();
        this.initTestimonialsCarousel();
        this.initSelect2();
        this.initRangeSlider();
        this.manageStickyNav();
        this.manageSideNav();
        this.manageEheight();

        $(window).resize(function() {
          that.manageEheight();
          that.manageStickyNav();
          that.manageSideNav();
        });

        $(window).on('scroll wheel', function() {
          that.manageStickyNav();
        });

        $(document).bind('gform_post_render', function(){
          that.initTooltip();
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
      initCookieWarning: function() {
        $('.btn-cookie').click(function(){
          $.cookie('accept-cookie', 1, { path: '/', expires: 7 });
          $('.cookie-warning').fadeOut('fast');
        });
      },
      initSideNav: function() {
        bouncefix.add('side-nav-container');

        $(".btn-toggle-side").click(function(e) {
          e.preventDefault();
          $('body').toggleClass("side-nav-active");
        });

        $(".side-nav-bg").click(function(){
          $('body').removeClass("side-nav-active");
        });
      },
      initNavDropDown: function() {
        $('.banner, .sticky-nav').find('.nav .dropdown').hover(function () {
          if (Modernizr.mq("(min-width: 992px)") && !$('body').hasClass('is-mobile')) {
            $('.banner nav .dropdown').not(this).removeClass('hover open');
            $(this).addClass("hover");
            $('body').addClass("dropdown-h-active");
          }
        }, function () {
          if (Modernizr.mq("(min-width: 992px)") && !$('body').hasClass('is-mobile')) {
            $(this).removeClass("hover");
            $('body').removeClass("dropdown-h-active");
          }
        });

        $('.dropdown-toggle').on("click", function(e) {
          if (!Modernizr.mq("(min-width: 992px)")) {
            e.stopPropagation();
          }
        });

        $('.ap-dropdown-menu').not('a').on("click", function(e) {
          e.stopPropagation();
        });

        $('.ap-dropdown').on('show.bs.dropdown', function () {
          $('body').addClass("dropdown-active");
        });
        $('.ap-dropdown').on('hide.bs.dropdown', function () {
          $('body').removeClass("dropdown-active");
        });
      },
      initScrollToTop: function() {
        $('.scroll-top').click(function(e){
          e.preventDefault();
          $("html, body").animate({ scrollTop: 0 }, 1000);
        });
      },
      initStickyNav: function() {
        $('.sticky-nav').Stickyfill();
      },
      initTooltip: function() {
        $('[data-toggle="tooltip"]').tooltip({
          placement: 'top',
          container: 'body'
        });
      },
      initTestimonialsCarousel: function() {
        $('.testimonials .carousel').slick({
          appendArrows: $('.testimonials .carousel-arrows'),
          prevArrow: "<button type='button' class='prev icon-chevron-left'><span class='sr-only'>" + ap_globals.prev_text + "</span></button>",
          nextArrow: "<button type='button' class='next icon-chevron-right'><span class='sr-only'>" + ap_globals.next_text + "</span></button>",
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          adaptiveHeight: true,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });
      },
      initSelect2: function() {
        var select2 = $(".select2").select2({
          placeholder: $(this).data('placeholder'),
          theme: $(this).data('theme'),
          templateResult: function(state) {
            if (!state.id) { return state.text; }

            var description =  $(state.element).data('description');
            var $state = $('<span class="title">' + state.text + '</span><span class="description">' + description + '</span>');

            return $state;
          }
        });

        select2.on("select2:open", function () {
          $('.select2-results__options').addClass('scrollbar-inner').scrollbar();
        });

        select2.on("change", function () {
          $.cookie('machine-cat', select2.find('option:selected').val(), { expires: 1, path: '/' });
        });
      },
      initRangeSlider: function() {
        var $slider = $(".range-slider");
        var min = $slider.data('min');
        var max = $slider.data('max');

        var vmin = min;
        var vmax = max;

        if(!$('body').hasClass('home')){
          if($.cookie('height-min') !== undefined) { vmin = $.cookie('height-min'); }
          if($.cookie('height-max') !== undefined) { vmax = $.cookie('height-max'); }
        }

        $slider.slider({
          range: true,
          min: min,
          max: max,
          values: [ vmin, vmax ],
          create: function() {
            $slider.find(".ui-slider-handle").first().text(vmin + ap_globals.height_unit);
            $slider.find(".ui-slider-handle").last().text(vmax + ap_globals.height_unit);

            $.cookie('height-min', vmin, { expires: 1, path: '/' });
            $.cookie('height-max', vmax, { expires: 1, path: '/' });
          },
          slide: function( event, ui ) {
            $slider.find(".ui-slider-handle").first().text(ui.values[0] + ap_globals.height_unit);
            $slider.find(".ui-slider-handle").last().text(ui.values[1] + ap_globals.height_unit);
          },
          change: function(event, ui) {
            $slider.find(".ui-slider-handle").first().text(ui.values[0] + ap_globals.height_unit);
            $slider.find(".ui-slider-handle").last().text(ui.values[1] + ap_globals.height_unit);

            $.cookie('height-min', ui.values[0], { expires: 1, path: '/' });
            $.cookie('height-max', ui.values[1], { expires: 1, path: '/' });
          }
        });
      },
      manageStickyNav: function() {
        var $nav = $('.sticky-nav');
        var distance = $('.banner').outerHeight();

        if (Modernizr.mq("(min-width: 992px)")) {
          if ($(window).scrollTop() >= distance) {
            $nav.addClass('stuck');
          } else {
            $nav.removeClass('stuck');
          }
        } else {
          $nav.removeClass('stuck');
        }
      },
      manageSideNav: function() {
        if (Modernizr.mq("(min-width: 992px)")) {
          $('body').removeClass("side-nav-active");
        }
      },
      manageEheight: function() {
        $(".eh-container").each(function() {
          var minWidth = parseInt($(this).data("min")) || 0;
          var children = $(this).find(".eh-child");
          var maxHeight = 0;

          if(Modernizr.mq("(min-width: " + minWidth + "px)")) {
            $(this).imagesLoaded(function() {
              children.each(function() {
                $(this).css({
                  "height": "auto"
                });

                if($(this).actual( 'outerHeight' ) > maxHeight) {
                  maxHeight = $(this).actual( 'outerHeight' );
                }
              });

              children.css({
                "height": maxHeight
              });
            });
          } else {
            children.css({
              "height": "auto"
            });
          }
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        this.initHomeFilter();
        this.initHomeCarousel();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      },
      initHomeFilter: function() {
        $sbox = $('.search-box');

        $sbox.find('.btn').click(function(){
          var url = $sbox.find('select option:selected').data('url');
          if (url === undefined) {
            url = $sbox.find('select').data('url');
            $.cookie('machine-cat', "all", { expires: 1, path: '/' });
          }

          window.location.href = url;
        });
      },
      initHomeCarousel: function() {
        $hcarousel = $('.home-carousel');

        $('.home-carousel').slick({
          autoplay: $hcarousel.data('autoplay'),
          autoplaySpeed: $hcarousel.data('speed'),
          dots: true,
          infinite: true,
          arrows: false,
          speed: 500,
          fade: true,
          cssEase: 'linear'
        });
      }
    },
    // Archive
    'archive': {
      init: function() {
        this.initVideoPopup();
        this.initAjaxQuery(ap_globals.paged);
      },
      initVideoPopup: function() {
        $('.video-popup').magnificPopup({
          disableOn: 700,
          type: 'iframe',
          mainClass: 'mfp-fade',
          removalDelay: 160,
          preloader: false,
          fixedContentPos: false
        });
      },
      initAjaxQuery: function(paged) {
        var that = this;
        var $main = $('.wrap .main');
        var next_page = parseInt(paged) + 1;

        $('.btn-ajax').click(function(e){
          e.preventDefault();
          var button = $(this);
          if(!button.hasClass('loading')) {
            button.addClass('loading');

            var action_type = button.data('action-type');
            if(action_type === "filter" || action_type === "reset") {
              next_page = 1;
            }

            if (action_type === "reset") {
              $(".select2").val('all').change();
              $slider = $(".range-slider");
              $slider.slider("values", [$slider.data('min'), $slider.data('max')]);
            }

            $.ajax({
              type: "POST",
              url: ap_globals.ajaxurl,
              dataType: "json",
              data: {
                action: "ap_ajax_query",
                nonce: ap_globals.nonce,
                post_type: ap_globals.post_type,
                is_search: ap_globals.is_search,
                paged: next_page
              },
              success: function (response) {
                if(action_type === "filter" || action_type === "reset") {
                  $main.html('');
                }

                $('.ajax-navigation').remove();
                $main.append(response.html);
                $('.results-number').text(response.found_posts);
                button.removeClass('loading');
                AC.common.manageEheight();
                AC.common.initTooltip();
                that.initVideoPopup();
                that.initAjaxQuery(next_page);
              }
            });
          }
        });
      }
    },
    // Single
    'single': {
      init: function() {
        var that = this;

        this.initReadMore();
        this.initShare();
        this.initImageSlider();
        this.initEasyZoom();
        this.initImagePopup();
        this.manageReadMore();
        this.manageResponsiveTableInfo();

        $(window).resize(function() {
          that.manageReadMore();
          that.manageResponsiveTableInfo();
        });
      },
      initReadMore: function() {
        $('.btn-read').click(function(e){
          e.preventDefault();

          $('.details').toggleClass('read-more');
          $('.btn-read.less, .btn-read.more').toggleClass('hidden');
        });
      },
      manageReadMore: function() {
        var $details = $('.details');
        var number = 0;

        if (Modernizr.mq("(min-width: 768px)")) {
          number = 1;
        }

        if ($details[number].scrollHeight < 275) {
          $details.removeClass('read-more');
          $('.btn-read').hide();
        } else {
          if($('.btn-read.less').hasClass('hidden')) {
            $details.addClass('read-more');
          }
          $('.btn-read').show();
        }
      },
      initShare: function() {
        $('.entry-share-btns .entry-share-btn a').on('click', function(e) {
          e.preventDefault();
          var link = $(this).attr('href');
          var width = 840;
          var height = 464;
          var popupName = 'popup_' + width + 'x' + height;
          var left = (screen.width-width) / 2;
          var top = 100;
          var params = 'width=' + width + ',height=' + height + ',location=no,menubar=no,scrollbars=yes,status=no,toolbar=no,left=' + left + ',top=' + top;
          window[popupName] = window.open(link, popupName, params);
          if (window.focus) {
            window[popupName].focus();
          }
          return true;
        });
      },
      initImageSlider: function() {
        $('.img-slider').on('beforeChange', function(){
          $('.img-slider img').removeClass('hidden');
        });

        var slider = $('.img-slider').slick({
          dots: false,
          arrows: false,
          swipe: false,
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          adaptiveHeight: true,
          fade: true,
          cssEase: 'linear'
        });

        $('.thumbnails a[data-type="image"]').click(function(e){
          e.preventDefault();
          $('.thumbnails a').removeClass('active');
          $(this).addClass('active');
          slider.slick('slickGoTo', $(this).index());
        });
      },
      initEasyZoom: function() {
        if(!$('body').hasClass('is-mobile')) {
          $('.easyzoom').easyZoom();
        }
      },
      initImagePopup: function() {
        var that = this;

        $('.img-slider .img-slide, .thumbnails a[data-type="iframe"]').on("click", function(e) {
          e.preventDefault();

          var clicked_href;
          if($(this).attr('href')) {
            clicked_href = $(this).attr('href');
          } else {
            clicked_href = $(this).find('a').attr('href');
          }

          var $clicked = $('.thumbnails a[href="' + clicked_href + '"]');
          var $items = $('.thumbnails a');
          var items = [];

          items.push({
            src: $clicked.attr("href"),
            type: $clicked.data("type")
          });

          $items.each(function() {
            if($(this).attr("href") !== $clicked.attr("href")) {
              items.push({
                src: $(this).attr("href"),
                type: $(this).data("type")
              });
            }
          });

          that.imagePopup(items);
        });
      },
      imagePopup: function(items) {
        var magnificPopup = $.magnificPopup.instance;

        magnificPopup.open({
          items: items,
          type: "image",
          gallery: {
            enabled: true,
            arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%" role="button"><i class="icon-chevron-%dir%" aria-hidden="true"></i></button>'
          },
          callbacks: {
            open: function() {
              $('.mfp-container').swipe( {
                swipeLeft:function() {
                  magnificPopup.next();
                },
                swipeRight: function() {
                  magnificPopup.prev();
                },
                threshold: 100
              });
            }
          }
        });
      },
      manageResponsiveTableInfo: function() {
        $('.table-responsive').each(function(){
          var el = $(this).get(0);
          var info = $(this).parent().find('.table-info');
          if(el.clientWidth < el.scrollWidth) {
            info.show();
          } else {
            info.hide();
          }
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = AC;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
